.cartes{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.cartes img{
    width: 25rem;
    border-radius: 0.5rem;
    margin: 1rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.26);
}

.cartes .row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Assurez-vous que l'overlay est au-dessus des autres éléments */
}

.full-screen-image {
    max-width: 90%;
    max-height: 90%;
}

@media (max-width: 768px) {
    .cartes .row{
        flex-direction: column;
        align-items: center;
    }

    .cartes img{
        width: 15rem;
    }

    .titleCarte{
        padding-left: 1.5rem;
        font-size: 1.25rem;
    }
}