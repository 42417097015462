@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.accueil{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: Poppins;
}

h4{
    margin-top: -1.2rem;
    font-weight: 300;
}

.image {
    width: 40rem;
    height: 35rem;
    background-image: url(../img/vue.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.5rem;
    box-shadow: 15px 15px 15px rgb(185, 185, 185);
}

.text{
    height: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;  
    font-size: 0.7rem;
}

.buttons p{
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-right: 0.5rem;
}

.buttons p:not(:hover){
    background-color: rgb(134, 172, 134);
    transition: 0.3s;
}

.buttons p:hover{
    background-color: rgb(139, 192, 139);
}

@media (max-width: 768px) {
    .image{
        width: 100%;
        height: 15rem;
    }

    .text{
        height: 10rem;
        margin-top: 2.5rem;
        text-align: center;
    }

    h1{
        font-size: 1.5rem;
    }

    h4{
        font-size: 1rem;
    }

    .buttons{
        display: none;
    }

    .accueil{
        padding: 0;
        margin: 0;
        flex-direction: column-reverse;
    }
}