card img{
    height: 3rem;
    width: 3rem;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(134, 172, 134);
    width: 20rem;
    height: 15rem;
    border-radius: 0.5rem;
    box-shadow: 15px 15px 15px rgb(174, 173, 173);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.card p{
    margin-top: -0.5rem;
}

#loc{
    width: 5rem;
}

#tel{
    width: 4rem;
}

.title{
    text-align: center;
    padding-top: 5rem;
} 

.cards{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.horaires{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5rem;
}

.horaires img{
    width: 20rem;
}

footer{
    padding-top: 1rem;
}

footer p{
    text-align: center;
    font-size: 0.8rem;
}

@media only screen and (max-width: 600px) {
    .card{
        height: 15rem;
        margin-bottom: 2rem;
    }

    .cards{
        flex-direction: column;
        align-items: center;
    }

    #loc{
        width: 2rem;
    }
    
    #tel{
        width: 1.75rem;
    }

    .horaires img{
        margin-top: -5rem;
        width: 15rem;
    }

    footer{
        margin-top: 4rem;
    }
}