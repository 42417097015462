@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.discover{
    font-family: Poppins;
    text-align: center;
}

.discover h3{
    font-weight: bold;
}

.discover h5{
    font-weight: 300;
    font-style: italic;
    margin-top: -1rem;
}

.icones img{
    width: 1.5rem;
}

#facebook{
    padding-left: 0.3rem;
}

@media (max-width: 768px) {
    .head{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}