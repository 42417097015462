.cardCrepe{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 3rem;
}

.cardCrepe img{
    width: 20rem;
    border-radius: 0.5rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.26);
}

.titleCrepes{
    padding-left: 3rem;
}

.link{
    text-align: center;
    padding-top: 5rem;
}

.link a{
    color: rgb(1, 124, 42);
}

@media (max-width: 768px) {
    .cardCrepe{
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 0;
    }

    .cardCrepe img{
        width: 10rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .link{
        padding-top: 2rem;
    }
}