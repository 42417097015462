@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.ligne{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 10rem;
    margin-right: 10rem;
    margin-top: 5rem;
}

.ligne2{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 10rem;
    margin-right: 10rem;
    margin-top: 5rem;
}

.ligne img{
    width: 20rem;
    border-radius: 0.5rem;
}

.ligne2 img{
    width: 20rem;
    border-radius: 0.5rem;
}

.ligne p{
    font-family: Nunito;
    text-align: justify;
    padding-right: 3rem;
}

#text{
    padding-right: 0;
    padding-left: 3rem;
}

@media (max-width: 768px) {
    body{
        margin: 0;
        padding: 0;
    }

    .ligne{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-left: 0rem;
        margin-right: 0rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 1rem;
    }

    .ligne p{
        padding: 0rem;
    }

    .ligne img{
        margin-top: 2rem;
    }

    .ligne2{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
        margin-left: 0rem;
        margin-right: 0rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 1rem;
    }

    #text{
        padding-left: 0rem;
    }

    .ligne2 img{
        padding-left: 0rem;
        margin-top: 1rem;
    }
}