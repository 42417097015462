@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 500;
    margin-bottom: 2rem;
}

.navbar ul{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 40vw;
    padding: 1vw;
    border-radius: 1rem;
    box-shadow: -0px 0px 220px rgba(255, 255, 255, 0.415);
}

.navbar ul:hover{
    background-color: #babbba93;
    transition: 0.3s;
}

.navbar ul:not(:hover){
    background-color: #cbcdcb93;
    transition: 0.3s;
}

.navbar ul li{
    list-style: none;
}

.navbar ul li a{
    text-decoration: none;
    font-family: Poppins;
    font-weight: 400;
    padding: 0.4rem;
    font-size: 0.95rem;
}

.navbar ul li a:hover{
    color: rgb(1, 164, 56);
    transition: 0.3s;
}

.navbar ul li a:not(:hover){
    color: rgb(1, 127, 43);
    transition: 0.3s;
}

@media (max-width: 768px) {
    .navbar{
        margin: 0;
        margin-bottom: 1rem;
        padding: 0%;
    }

    .navbar ul{
        width: 30vh;
    }

    .navbar ul li a{
        font-size: 0.85rem;
    }
}